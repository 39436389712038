<script setup>

</script>
<template>
    <div class="layout layout-default mt-[var(--app-offset-top)] bg-nuit text-white ">
        <div class="font-sans antialiased">
            <PartialsHeader />
            <div
                class="page z-12 -mb-12 mt-[var(--guimauve-offset-top)] min-h-screen overflow-x-clip transition duration-650"
            >
                <slot />
            </div>
            <PartialsFooter />
        </div>
    </div>
</template>
